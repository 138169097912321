<template>
  <div>
    <h1 class="main-header">
      <translate>Utility</translate>
    </h1>

    <CCard>
      <CCardHeader>
        <h5 class="my-2" v-translate>Non-buyer user list</h5>

        <div class="card-header-actions">
          <PButton class="btn btn-primary btn-sm"
                   @click="exportCsvNonBuyerUsers"
                   :loading="isExportingNonBuyerUsers"
                   :loading-text="$gettext('Generating file...')"
                   spinner-color="secondary"
          >

            <translate>Download</translate>
          </PButton>
        </div>
      </CCardHeader>

      <!--      <template v-if="isPromo">
              <CCardBody>
                <div class="text-warning">
                  <translate>Function not active for promotional events.</translate>
                </div>
              </CCardBody>
            </template>-->

      <template>
        <CCardBody>
          <p>
            <translate>Export a CSV file with users who have checked in for the current event but have not
              yet made a purchase.
            </translate>
          </p>

          <CInputCheckbox
              :label="$gettext('Ignore privacy acceptance')"
              :value="ignorePrivacy"
              @update:checked="ignorePrivacy = $event"
              custom
          />

          <CInputCheckbox
              :label="$gettext('Exclude users with 0 photos')"
              :value="ignoreUsersWithNoPhotos"
              :disabled="includeUsersWithAnyBuyablePhotos"
              @update:checked="ignoreUsersWithNoPhotos = $event"
              custom ref="ignoreUsersWithNoPhotosCheckbox"
              class="mt-2"
          />

          <CInputCheckbox
              :label="$gettext('Include all purchasable photo checkins')"
              :value="includeUsersWithAnyBuyablePhotos"
              @update:checked="includeUsersWithAnyBuyablePhotos = $event; $refs.ignoreUsersWithNoPhotosCheckbox.state = $event"
              custom
              class="mt-2"
          />

          <CInputCheckbox
              :label="$gettext('Exclude users with pending bank payments in the last 5 days')"
              :value="ignoreUsersWithRecentBankPayments"
              @update:checked="ignoreUsersWithRecentBankPayments = $event"
              custom
              class="mt-2"
          />
        </CCardBody>

      </template>
    </CCard>

    <CCard class="mt-2" v-if="!isPromo">
      <CCardHeader>
        <h5 class="my-2" v-translate>List of users who have purchased</h5>
        <div class="card-header-actions">
          <PButton class="btn btn-primary btn-sm"
                   @click="exportCsvBuyerUsers"
                   :loading="isExportingBuyerUsers"
                   :loading-text="$gettext('File creation in progress...')"
                   spinner-color="secondary">
            <translate>Download</translate>
          </PButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <p v-translate>Export a CSV file with users who have made a purchase for the current event.</p>
      </CCardBody>
    </CCard>

    <CCard class="mt-2">
      <CCardHeader>
        <h5 class="my-2" v-translate>List of check-ins</h5>
        <div class="card-header-actions">
          <PButton class="btn btn-primary btn-sm"
                   @click="exportCsvListCheckins"
                   :loading="isExportingCheckins"
                   :loading-text="$gettext('File creation in progress...')"
                   spinner-color="secondary">
            <translate>Download</translate>
          </PButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <p v-translate>Export a CSV file of check-ins for the current event.</p>
      </CCardBody>
    </CCard>

    <CCard class="mt-2">
      <CCardHeader>
        <h5 class="my-2" v-translate>
          List of all BIB numbers released</h5>
        <div class="card-header-actions">
          <PButton class="btn btn-primary btn-sm"
                   @click="exportCsvAllUsers"
                   :loading="isExportingAllUsers"
                   :loading-text="$gettext('File creation in progress...')"
                   spinner-color="secondary">
            <translate>Download</translate>
          </PButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <p v-translate>Export a CSV file with all BIB numbers and related links to the Pica album landing page.</p>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import UtilityService from "@/services/utility.service"
import PButton from "@/components/PButton"

export default {
  name: "EventUtility",

  components: {
    PButton
  },

  data() {
    return {
      ignorePrivacy: false,
      ignoreUsersWithNoPhotos: false,
      includeUsersWithAnyBuyablePhotos: false,
      ignoreUsersWithRecentBankPayments: false,
      isExportingNonBuyerUsers: false,
      isExportingBuyerUsers: false,
      isExportingAllUsers: false,
      isExportingCheckins: false,
    }
  },

  computed: {
    ...mapGetters({
      picaServiceId: 'event/picaServiceId',
      isPromo: 'event/isPromo',
    }),
  },

  methods: {
    exportCsvNonBuyerUsers() {
      let params = {}
      if (this.ignorePrivacy) {
        params['ignore-privacy'] = true
      }

      if (this.includeUsersWithAnyBuyablePhotos) {
        params['any-buyable-photos'] = true
      } else if (this.ignoreUsersWithNoPhotos) {
        params['ignore-without-photos'] = true
      }

      if (this.ignoreUsersWithRecentBankPayments) {
        params['ignore-pending-bank-payment'] = true
      }

      this.isExportingNonBuyerUsers = true
      UtilityService.exportCsvUsersNonBuyer(this.picaServiceId, params)
          .then(() => {
            this.isExportingNonBuyerUsers = false
          })
    },

    exportCsvBuyerUsers() {
      this.isExportingBuyerUsers = true
      UtilityService.exportCsvUsersBuyer(this.picaServiceId)
          .then(() => {
            this.isExportingBuyerUsers = false
          })
    },

    exportCsvAllUsers() {
      this.isExportingAllUsers = true
      UtilityService.exportCsvAllUsers(this.picaServiceId)
          .then(() => {
            this.isExportingAllUsers = false
          })
    },

    exportCsvListCheckins() {
      this.isExportingCheckins = true
      UtilityService.exportCsvListCheckins(this.picaServiceId)
          .then(() => {
            this.isExportingCheckins = false
          })
    }
  }
}
</script>
